@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Raleway:wght@400;700&display=swap');
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Raleway
}

html {
  height: 100%;
}

#root {
  height: 100%;
}
